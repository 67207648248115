// import { state } from "./content.model";

export const StateList: any[] = [
  { name: 'Abia', code: 'AB' },
  { name: 'Adamawa', code: 'AD' },
  { name: 'Anambra', code: 'AN' },
  { name: 'Akwa Ibom', code: 'AI' },
  { name: 'Bauchi', code: 'BA' },
  { name: 'Bayelsa', code: 'BY' },
  { name: 'Benue', code: 'BE' },
  { name: 'Borno', code: 'BO' },
  { name: 'Cross River', code: 'CR' },
  { name: 'Delta', code: 'DT' },
  { name: 'Ebonyi', code: 'EB' },
  { name: 'Enugu', code: 'EG' },
  { name: 'Edo', code: 'ED' },
  { name: 'Ekiti', code: 'EK' },
  { name: 'FCT - Abuja', code: 'FT' },
  { name: 'Gombe', code: 'GM' },
  { name: 'Imo', code: 'IM' },
  { name: 'Jigawa', code: 'JG' },
  { name: 'Kaduna', code: 'KD' },
  { name: 'Kano', code: 'KN' },
  { name: 'Katsina', code: 'KT' },
  { name: 'Kebbi', code: 'KB' },
  { name: 'Kogi', code: 'KG' },
  { name: 'Kwara', code: 'KW' },
  { name: 'Lagos', code: 'LA' },
  { name: 'Nasarawa', code: 'NW' },
  { name: 'Niger', code: 'NG' },
  { name: 'Ogun', code: 'OG' },
  { name: 'Ondo', code: 'OD' },
  { name: 'Osun', code: 'Os' },
  { name: 'Oyo', code: 'OY' },
  { name: 'Plateau', code: 'PL' },
  { name: 'Rivers', code: 'RV' },
  { name: 'Sokoto', code: 'SO' },
  { name: 'Taraba', code: 'TB' },
  { name: 'Yobe', code: 'YB' },
  { name: 'Zamfara', code: 'ZM' }
];