
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormatterService } from '../../@core/service';
// import { ContentComponent } from '../content';
import { StateService } from '../../@core/service';

import { ToastrService } from 'ngx-toastr';

interface Card {
  id: string;
  card_id?: string
  title: string;
  color?: string;
  image?: any;
  url?: string;
  body: string
}
@Component({
  selector: 'state-edit',
  templateUrl: './edit.component.html',
  styleUrls: ['./edit.component.css']
})
export class EditComponent implements OnInit  {
  @Input() states: any;
  @Input() selected_states: any;
  @Output() close_me: EventEmitter<boolean> = new EventEmitter<boolean>();
  public title: string = null;
  public card_image: any = [];

  public note: {
    card: Card[];
    title: string;
    content: {}[];
    description?: string;
    published_at?: any;
    media?: string;
    media_type?: string;
    state?: string;
    state_code?: string;
    stateguideId?: string;
  };
  //  public content: 'testing testing';

   editorsStyle = {
    height: '600px',
    'font-size': '16px'
   }
   cardEditorsStyle = {
    height: '300px',
    'font-size': '16px'
   }
  // public note: any = {};
  constructor(private formatterService: FormatterService, private service: StateService,  private toastrService: ToastrService) {
  }
  ngOnChanges() {
    console.log(this.selected_states)

        // this.states = JSON.parse(this.states)
        // console.log('1',this.states)
        let content = this.getContentForState(this.states);
        let title = this.states ? this.states.title :  "";
        let stateguideId = this.states ? this.states.stateguideId :  "";
        this.note = null;
        this.note = {
          card: [],
          title,
          content,
          stateguideId,
        };
        if(this.states){
          if(this.states.sub_contents && this.states.sub_contents.length >= 1){
           const selected_holder = this.states.sub_contents;
            for (let i = 0; i < selected_holder.length; i++) {
             let card_content =  this.getContentForCard(selected_holder[i]);
             if(card_content.image){
              this.card_image.push(card_content.image)
              // delete card_content.image;
             }
             this.addCard(card_content)
            }
            // console.log(this.note)
          }
        } else {
          let card_content =  this.getEmptyContentForCard();
          this.addCard(card_content)
        }

  }

  created(editor: any) {
    if(this.states && this.states.media_type == 'image' && this.states.media){
      const range = editor.getSelection(true);
      editor.insertEmbed(range.index, 'image', this.states.media, 'user')   
    }
 }

  createdCard(editor: any, id: string) {
   if(this.card_image.length >= 1){
     const x  = this.card_image.find((x: any) => x.id === id)
     if(x){
      const range = editor.getSelection(true);
      editor.insertEmbed(range.index, 'image', x.url, 'user')
     }
   }
}

  ngOnInit() {

  }
  public addCard(obj: any = null): void {
    if(obj){
      if (obj instanceof Array) {
        this.note.card = obj;
      }  else {
        this.note.card.push(obj);
      }
      // console.log(this.note);
    }else {
      this.note.card.push({
        id: Date.now() + this.formatterService.makeid(9), // <--- uniqueness hook.
        card_id: '',
        title: '',
        color: '',
        image: '',
        url: '',
        body: '',
      })
    }
  }

  // public removeCard(index: number): void {
  //   console.log( this.note.card[index])
  //   // if(){

  //   // }
  //   // this.note.card.splice(index, 1);
  // }
  // saveNote(event: any) {
  //   console.log(this.title);
  //   // this.todos.push(this.todoObj);
  //   event.preventDefault();
  // }
  onSubmit() {
   this.formatText(this.note);
    event.preventDefault();
  }
  public formatText(note: any) {
     let content: any = {}
    const contentRaw = this.formatterService.getContent(note.content, ['intro', 'media']);
    content.stateguideId =  note.stateguideId;
    const cards  = this.getCards(note, note.stateguideId);
    content.sub_contents = cards;
    content.title = note.title;
    content.content = contentRaw.intro;
    content.description = this.formatterService.getDescription(contentRaw.intro);
    delete content.intro;
    if(contentRaw.media && contentRaw.media.length >= 0 && contentRaw.media[0].base64){
      console.log('tryingz', contentRaw.media[0])
      // fix base64 or url
      content.media = contentRaw.media[0].base64;
      content.media_type = contentRaw.media[0].type || "image";
    }else {
      content.media = "";
      content.media_type = "image";
    }

    content.published_at = this.formatterService.setPublishDate();
    content.state = this.selected_states.name;
    content.state_code = this.selected_states.code.toUpperCase();
    // const links = this.formatterService.getLinks(note.intro);
    //alert('SUCCESS!! :-)' + JSON.stringify(content));
   this.saveContent(content);
  // console.log(content);
    event.preventDefault();
  }

  public getCards(note: any, id: string) {
    let cards: any[] = [];
    const card = note.card;
     if(card.length > 0){
      for (let i = 0; i < card.length; i++) {
        // console.log(card[i])
        const title = card[i].title;
        const content = this.formatterService.getContent(card[i].body, ['intro', 'media']);
        console.log('trying', content.media);
        cards.push({
          id: card[i].card_id,
          stateguideId: id,
          title: title,
          body: content.intro,
          image: (content.media && content.media.length >= 0 && content.media[0].base64) ? content.media[0].base64 : "",
          color: card[i].color,
          // url: content.url
      });
     }
  }
 return cards;
}


public saveContent(obj: any){
  this.service.addState(obj).subscribe(
    (res: any) => {
      console.log(res)
      this.toastrService.success(res.msg);
    alert('SUCCESS!! :-)' + res.msg);

    },
    err => {
      console.log(err);
    }
  );
}

public getContentForState(states: any){
  let content: any = [];
  if(states && states.content){
    content.push(
    {
    "insert": this.states.content
    },
    {"insert": "\n"});
}else {
  content.push({
    "insert": ""
  })
}
// fix list
// if(this.states.links) {
//   content.push({"insert": "\n"});
//   for (let i = 0; i < this.states.links.length; i++) {
//     content.push({attributes: {link: this.states.links[i].url || 'links.com'}, insert: this.states.links[i].text}, {attributes: {list: "bullet"}, insert: "\n"})
//   }
// }
return content;
}


public getContentForCard(card_content: any):Card{
        let body: any = [];
        const title = card_content.title;
        // fix
        // const url = card_content.url || "";
        let image: any;
        const id = Date.now() + this.formatterService.makeid(9);
        const card_id = card_content.id;
        const color = this.formatterService.generateColor(card_content, this.selected_states);
        // const color = "#EA5B3A"
        if(card_content.body != "") {
        body.push(
        {
        "insert": card_content.body
        },
        {"insert": "\n"});
      }
// if(card_content.links.length >= 1 && card_content.links.text) {
//   body.push({"insert": "\n"});
//   for (let i = 0; i < card_content.links.length; i++) {
//     body.push({attributes: {link: card_content.links[i].url || 'links.com'}, insert: card_content.links[i].text}, {attributes: {list: "bullet"}, insert: "\n"})
//   }
// }  
if(card_content.image != "") {
  // for (let i = 0; i < card_content.media.length; i++) {
   image = {id, url: card_content.image};
  //  image[id] = card_content.image
  // }
}   
return  {
    id, // <--- uniqueness hook.
    card_id,
    title,
    body,
    image,
    color,
    // url,
  };
}

public getEmptyContentForCard():Card{
  let card: any = [];
  const CardTitles = this.formatterService.getCardTitles(this.selected_states);
  for (let i = 0; i < CardTitles.length; i++) {
    card.push({
      id: Date.now() + this.formatterService.makeid(9), // <--- uniqueness hook.
      card_id: '',
      title: CardTitles[i].title,
      color: CardTitles[i].color,
      image: '',
      url: '',
      body: '',
    })
   }
return  card;
}
}
