// import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';

import { StateGuideRoutes } from '@app/state-guide/state-guide.routing';
import { StateGuideComponent } from '@app/state-guide/state-guide/state-guide.component';
import { SharedModule } from '@app/shared';
import { EditComponent } from './edit/edit.component';

@NgModule({
  imports: [
    // FormsModule,
    // CommonModule,
    // NgbModule,
    SharedModule,
    RouterModule.forChild(StateGuideRoutes)
  ],
  declarations: [StateGuideComponent, EditComponent]
})
export class StateGuideModule {}
