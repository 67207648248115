import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseAPIClass } from '@app/@core/class/baseAPI.class';

@Injectable()
export class StateService extends BaseAPIClass {
  public headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.baseUrl = '/StateGuide';

  }

  addTodo(obj: any) {
    return this.httpClient.post(this.baseUrl, obj);
  }

  getState(item: string) {
    console.log(JSON.stringify(item));
    return this.httpClient.post(`${this.baseUrl}/GetStateGuidebyStateCode`, JSON.stringify(item), {
      headers: this.headers
    });
  }

  addState(obj: any) {
    console.log(JSON.stringify(obj));
    return this.httpClient.post(`${this.baseUrl}/AddStateGuide`, JSON.stringify(obj),  { headers: this.headers });
  }

  updateTodo(obj: any) {
    return this.httpClient.put(this.baseUrl, obj);
  }

  removeTodo(id: any) {
    return this.httpClient.delete(this.baseUrl + id);
  }
}
