import { Routes } from '@angular/router';
import { AuthenticationGuard } from '@app/@core';

import { StateGuideComponent } from '@app/state-guide/state-guide/state-guide.component';

export const StateGuideRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        component: StateGuideComponent,
        data: {
          title: 'State Guide'
        }
      }
    ]
  }
];
