import { Component, OnInit } from '@angular/core';
import { categoryList } from '../../@core/model/category';
import { ModalDismissReasons, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { ContentService } from '../../@core/service';

@Component({
  templateUrl: './content.component.html',
  styleUrls: ['./content.component.css']
})
export class ContentComponent implements OnInit {
  category_list: any[];
  selected_category: {};
  selected_note: {};
  title = 'Categories Guide';
  public search: any = '';
  public data: any = {};
  public selector: string = null;
  closeResult: string;
  public query : any = '';
  public note: any[] = null;
  constructor(private modalService: NgbModal, private service: ContentService) {
    
  }
  
  ngOnInit() {
    this.category_list = categoryList;
    // console.log('selector', this.selector);
  }
  // open() {
  //   this.modalService.open(NgbdModal2Content, {
  //     size: 'lg'
  //   });

  open(content: any, item: any) {
    this.getContent(item);
    this.modalService.open(content, { size: 'lg' }).result.then(
      result => {
        this.closeResult = `Closed with: ${result}`;
      },
      reason => {
        this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
      }
    );
  }
  getCategory(selected_category: {}) {
    this.selector = 'new';
    this.selected_note = null;
    this.selected_category = selected_category;
  }

  editNote(selected_note: {}) {
    this.selector = 'edit';
    this.selected_note = selected_note;
    this.selected_category = null;
    this.modalService.dismissAll();
  }

  private getDismissReason(reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return `with: ${reason}`;
    }
  }

  getContent(item: any) {
    // console.log(this.note);
    this.service.getContentByCategory(item).subscribe(
      (res: any) => {
        if (res.data && res.data.length > 0) {
          this.note = res.data;
          console.log(this.note);
        }
        // Object.keys(res).forEach((key) => {
        //   this.data[key] = res[key];
        // });
      },
      err => {
        console.log(err);
      }
    );
  }
}
