
import slugify from "slugify";
import  * as moment from "moment";
// import { NewContentComponent } from "@app/content/new";
interface Result {
  intro?: any;
  media?: any[];
  list?: any[]
  links?: any[]
}
export class FormatterService {

  constructor() {
  }
  getCardTitles( selected_states: any){
    const name: string = selected_states ? `${selected_states.name} State` : " ";
    return [
      {title: "People & Culture", color: "#4CAAD8"},
      {title: "Notables", color: "#199D13"},
      {title: "Geography & Weather", color: "#EA5B3A"},
      {title: "Geography & Climate", color: "#EA5B3A"},
      {title: "Interesting Fact", color: "#F7F7F7"},
      {title: "For More Information", color: "#062f86"},
      {title: `Travelling to ${name}`, color: "#199D13"},
    ]
  }
  generateColor(body: any, selected_states: any) {
    let color: string = body.color; 
    const name: string = selected_states ? `${selected_states.name} State` : " ";
    if(body.color === null){
      switch (body.title) {
        case "People & Culture": color = "#4CAAD8"
          
          break;
          case "Notables":color = "#199D13"
          
          break;
          case "Geography & Weather":color = "#EA5B3A"
          
          break;
          case "Geography & Climate":color = "#EA5B3A"
          
          break;
          
          case "Interesting Fact":color = "#F7F7F7"
          
          break;
          case "For More Information":color = "#062f86"
          
          break;
          case `Travelling to ${name}`:color = "#199D13"
          
          break;
      
        default:color = "#199D13"
          break;
      }

    }
      return color
  }
  getDescription(content: string) {
    // console.log(content);
    const NewContent =  content.replace(/(([^\s]+\s\s*){30})(.*)/,"$1…");
    // console.log(NewContent);
     return NewContent;
  }
  makeid(length: number) {
    var result           = '';
    var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    var charactersLength = characters.length;
    for ( var i = 0; i < length; i++ ) {
       result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
 }
isBase64(str: string) {
  // console.log('string', str);
  if (str ==='' || str.trim() ===''){ return false; }
  try {
    // console.log( btoa(atob(str)) == str);

      return btoa(atob(str)) == str;
  } catch (err) {
      return false;
  }
}
  getContent(obj: any, options: any): any {
      let media: any[] = [];
      let links: any[] = [];
      let list: any[] = [];
      let intro_array: any[]= [];
      let intro: string = '';
      // const obj = JSON.parse(object)
      // const obj = object;
      if (obj  && !obj.ops  && obj instanceof Array) {
        obj = { ops: obj};
      } 
      if (obj.ops) {
    //    return  obj.ops.map((item: any) => {
      console.log(obj.ops)
        for (let i = 0; i < obj.ops.length; i++) {
            if(obj.ops[i].insert && obj.ops[i].insert.image && options.includes('media')){
              // console.log(obj.ops[i]);
              const strImage = obj.ops[i].insert.image.replace(/^data:image\/\w+;base64,/, '');
              // console.log(strImage);
            if(this.isBase64(strImage)){
              // console.log('yes');
              media.push({
                type: 'image',
                base64: strImage,
                caption: '',
            })
            } else {
              media.push({
                type: 'image',
                url: strImage,
                caption: '',
            })
            }
        }
        if(obj.ops[i].insert && obj.ops[i].insert.video  && options.includes('media')){
            media.push({
                type: 'video',
                url: obj.ops[i].insert.video,
                caption: '',
            })
        }
       
        if(obj.ops[i].attributes && obj.ops[i].insert &&  obj.ops[i].attributes.link  && options.includes('links')){
            links.push({
                url: obj.ops[i].attributes.link,
                text: obj.ops[i].insert,
            })
        }
        if(obj.ops[i].insert && !obj.ops[i].insert.video && !obj.ops[i].insert.image && typeof(obj.ops[i].insert) == 'string'){
           console.log(typeof(obj.ops[i].insert));
           console.log(i, obj.ops[i].insert);
          //  intro_array.push(obj.ops[i].insert)
           if(obj.ops[i].attributes){
               if(!obj.ops[i].attributes.link && !obj.ops[i].attributes.list){
                intro_array.push(obj.ops[i].insert) 
               }
           } else {
            if( obj.ops[i].insert == "↵" || obj.ops[i].insert == "↵↵" || obj.ops[i].insert == "↵↵↵"){
                intro_array.push("\n")
               } else {
                intro_array.push(obj.ops[i].insert) 
               }
           }
 
        }
        
        }
        
      }
      // if(media.length == 0){
      //   media.push({});
      // }
      // if(links.length == 0){
      //   links.push({});
      // }
      // if(list.length == 0){
      //   list.push({});
      // }
      if(intro_array.length == 0){
        intro = '';
      }else {
        intro = intro_array.join("  ");
      }
      // console.log(intro_array);
      const result: Result = {};
      if(options.includes('list')){
        result.list = list
      }
      if(options.includes('media')){
        result.media = media
      }
      if(options.includes('intro')){
        result.intro = intro
      }
      if(options.includes('links')){
        result.links = links
      }
      // console.log('links',result);

      return result;
  }
  getContent2(type: string, obj: any, idObject: any,  options: any): any {
    let media: any[] = [];
    let links: any[] = [];
    let list: any[] = [];
    let intro_array: any[]= [];
    let intro: string = '';
    // const obj = JSON.parse(object)
    // const obj = object;
    // console.log(type, obj.ops, obj);

    if (obj  && !obj.ops  && obj instanceof Array) {
      obj = { ops: obj};
      // console.log(type, obj);
    } 
    if (obj.ops) {
  //    return  obj.ops.map((item: any) => {
    // console.log(obj.ops)
      for (let i = 0; i < obj.ops.length; i++) {
      if(obj.ops[i].insert && obj.ops[i].insert.image && options.includes('media')){
            // console.log(obj.ops[i]);
            const strImage = obj.ops[i].insert.image.replace(/^data:image\/\w+;base64,/, '');
            // console.log(strImage);
            let newObj: any;
            newObj = {
              type: 'image',
              caption: '',
          }
          if(type === 'content'){
            newObj.mediumId = idObject.mediumId;
            newObj.contentId =  idObject.contentId;
          }else if(type === 'sub_content') {
            newObj.sub_MediumId = idObject.sub_MediumId
            newObj.sub_contentId =  idObject.sub_contentId;
          } else if(type === 'card') {
            newObj.card_MediumId = idObject.card_MediumId
            newObj.cardId = idObject.cardId
          }
          if(this.isBase64(strImage)){
            // console.log('yes');
            newObj.base64 = strImage;
            media.push(newObj)
          } else {
            newObj.url = strImage;
            media.push(newObj)
          }
      }
      if(obj.ops[i].insert && obj.ops[i].insert.video  && options.includes('media')){
         // console.log(strImage);
         let newObj: any;
         newObj = {
           type: 'video',
           caption: '',
           url: obj.ops[i].insert.video,
       }
       if(type === 'content'){
         newObj.mediumId = idObject.mediumId;
         newObj.contentId =  idObject.contentId;
       }else if(type === 'sub_content') {
         newObj.sub_MediumId = idObject.sub_MediumId
         newObj.sub_contentId =  idObject.sub_contentId;
       } else if(type === 'card') {
         newObj.card_MediumId = idObject.card_MediumId
         newObj.cardId = idObject.cardId
       }
          media.push(newObj)
      }
      // console.log(type, idObject.linkArray);

      if(obj.ops[i].attributes && obj.ops[i].insert &&  obj.ops[i].attributes.link  && options.includes('links')){

        // const linkId = (idObject.linkArray[i]) ? idObject.linkArray[i].linkId : '';
        // console.log('linkId_now', linkId, i);

        // console.log('linkId_now', idObject.linkArray[i].linkId);
        // const linkId = (idObject.linkArray[i]) ? idObject.linkArray[i].linkId : '';
        // console.log('linkId_now', linkId);

          const temp = {
            url: obj.ops[i].attributes.link,
            text: obj.ops[i].insert,
            // linkId,
            contentId: idObject.contentId,
        }
         
          if(obj.ops[i].insert ) {
            links.push(temp);
          }
      }
      // console.log(type, obj.ops[i].insert);
      if(obj.ops[i].insert && !obj.ops[i].insert.video && !obj.ops[i].insert.image && typeof(obj.ops[i].insert) == 'string'){
         if(obj.ops[i].attributes){
             if(!obj.ops[i].attributes.link && !obj.ops[i].attributes.list){
              // console.log(i, obj.ops[i].insert);
              intro_array.push(obj.ops[i].insert) 
             }
         } else {
          if( obj.ops[i].insert == "↵" || obj.ops[i].insert == "↵↵" || obj.ops[i].insert == "↵↵↵"){
            intro_array.push("\n")
             } else {
              intro_array.push(obj.ops[i].insert) 
             }
         }

      }
      } 
    }
    // if(media.length == 0){
    //   media.push({});
    // }
    // if(links.length == 0){
    //   links.push({});
    // }
    // if(list.length == 0){
    //   list.push({});
    // }
    if(intro_array.length == 0){
      intro = '';
    }else {
      intro = intro_array.join("  ");
    }
    // console.log(intro_array);
    const result: Result = {};
    if(options.includes('list')){
      result.list = list
    }
    if(options.includes('media')){
      result.media = media
    }
    if(options.includes('intro')){
      result.intro = intro
    }
    if(options.includes('links')){
      for (let i = 0; i < links.length; i++) {
        console.log(idObject);
        let xx;
        xx  = (idObject.linkArray)? idObject.linkArray.find((x: any) => x.id === i): null;
        if(type === "card") {
          links[i].card_LinkId = (xx) ? xx.card_LinkId : ''
        } else links[i].linkId = (xx) ? xx.linkId : '';
      }
      result.links = links
    }
    console.log(type,result.intro);
    return result;
}

  slugly(str: string){
    return slugify(str, '_');
  }

  setPublishDate(){
    // var DateObj = new Date();
    // return DateObj.getFullYear() + '-' + ('0' + (DateObj.getMonth() + 1)).slice(-2) + '-' + ('0' + DateObj.getDate()).slice(-2);
    moment.locale('en-ng')
    return moment().toISOString();
  }

}
