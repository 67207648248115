import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BaseAPIClass } from '@app/@core/class/baseAPI.class';

@Injectable()
export class ContentService extends BaseAPIClass {
  public headers = new HttpHeaders().set(
    'Content-Type',
    'application/json; charset=utf-8'
  );

  constructor(protected httpClient: HttpClient) {
    super(httpClient);
    this.baseUrl = '/Contents';

  }

  addContent(obj: any) {
    console.log(JSON.stringify(obj));
    return this.httpClient.post(`${this.baseUrl}/AddContent`, JSON.stringify(obj),  { headers: this.headers });
  }

  getAllContent(item: string) {
    return this.httpClient.post(`${this.baseUrl}/GetAllContent`, null, { headers: this.headers });
  }
  getContentByCategory(item: any) {
    console.log(JSON.stringify(item));
    return this.httpClient.post(`${this.baseUrl}/GetContentbyCategory`, JSON.stringify(item.code), { headers: this.headers });
  }

  updateTodo(obj: any) {
    return this.httpClient.put(this.baseUrl, obj);
  }

  removeTodo(id: any) {
    return this.httpClient.delete(this.baseUrl + id);
  }
}
