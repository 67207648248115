import { Component, Input, Output, EventEmitter } from '@angular/core';
import { FormatterService } from '../../@core/service';
// import { ContentComponent } from '../content';
import { ContentService } from '../../@core/service';
import { ToastrService } from 'ngx-toastr';
interface Card {
  id: number;
  title: string;
  body: string;
}
@Component({
  selector: 'new-content',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class NewContentComponent {
  @Input() selected_category: any;
  @Output() close_me: EventEmitter<boolean> = new EventEmitter<boolean>();
  public title: string = null;
  // public  note: {
  //   title: '',
  //   content: '',
  // };
  public note: {
    card: Card[];
    title: string;
    intro: any;
    media: {}[];
    sub_title: string;
    sub_content_title: string;
    sub_content_intro: any;
    sub_content_sub_title: string;
  };
   public content: 'testing testing';

   editorsStyle = {
    height: '300px'
   }
  // public note: any = {};
  constructor(private formatterService: FormatterService, private service: ContentService, private toastrService: ToastrService) {
    this.note = {
      card: [],
      title: '',
      intro:     [
        {
          "insert": "Hello "
        },
        {
          "insert": "World!",
          // "attributes": {
          //   "bold": true
          // }
        },
        // {
        //   "insert": "\n"
        // }
      ],
      media: [],
      sub_title: '',
    sub_content_title: '',
    sub_content_intro: null,
    sub_content_sub_title: '',
    };
    // Add an initial pet form-entry.
    this.addCard();
  }

  public addCard(): void {
    this.note.card.push({
      id: Date.now(), // <--- uniqueness hook.
      title: '',
      body: ''
    });
  }

  public removeCard(index: number): void {
    this.note.card.splice(index, 1);
  }
  saveNote(event: any) {
    console.log(this.title);
    // this.todos.push(this.todoObj);
    event.preventDefault();
  }
  onSubmit() {
   this.formatText(this.note);
    event.preventDefault();
  }
  public formatText(note: any) {
    // alert('SUCCESS!! :-)' + JSON.stringify(note.intro));
 
      const intro = note.intro;
    const title = note.title
    const title_slug = this.formatterService.slugly(note.title);
    const published_at = this.formatterService.setPublishDate();
    const linkObj: any = {};
    linkObj.linkArray = [];
    linkObj.contentId = "";
    linkObj.mediumId = "";
    console.log('intro', intro, linkObj);
    const content = this.formatterService.getContent2('content', intro, linkObj, ['intro', 'media', 'links']);
    const cards  = this.getCards(note);
    const sub_content  = this.subContent(note);
    sub_content[0].cards = cards;
    content.sub_content = sub_content;
    content.title = title;
    content.sub_title = note.sub_title;
    content.title_slug = title_slug;
    content.published_at = published_at;
    content.categories = this.selected_category.code;
    content.tags = [];
    content.contentId =  "",
    console.log(content.links)
    // if(content.links && content.links.length >= 1 ) {
    //   console.log(content.links);
    // } else {
    //   delete content.links
    // }
    content.logo = "https://gallery.mailchimp.com/7532fb9ddd325fea5d2e3d3ba/images/1e968ef0-6935-4a3f-a725-ac7d47d8d300.png",
    // const links = this.formatterService.getLinks(note.intro);
    // alert('SUCCESS!! :-)' + JSON.stringify(content.media));
    console.log(content);
    this.saveContent(content);
    // console.log(content);

    event.preventDefault();
  }

  public getCards(note: any) {
    let cards: any[] = [];
    const card = note.card;
     if(card.length > 0){
      for (let i = 0; i < card.length; i++) {
        const title = card[i].title;
        const linkObj: any = {};
        linkObj.cardId = card[i].cardId;
        linkObj.card_MediumId = (card[i].card_MediumId ) ? card[i].card_MediumId : '';  
        const content = this.formatterService.getContent2('card', card[i].body,linkObj, ['intro', 'media', 'links', 'list']);
      let card_obj: any = {
        cardId: card[i].cardId,
        // contentId: id,
        title: title,
        body: content.intro,
        media: content.media,
        list: content.list
    }
    if (content.links.length >= 1 && content.links[0].text ) { card_obj.links = content.links };
      cards.push(card_obj);
     }
  }
 return cards;
}
public subContent(note: any) {
  let sub_content: any[] = [];
  const intro = note.sub_content_intro;
      const title = note.sub_content_title;
      const sub_title = note.sub_content_sub_title;
      const linkObj: any = {};
      linkObj.contentId = '';
      linkObj.sub_contentId = '';
      linkObj.sub_MediumId = '';
      const content = this.formatterService.getContent2('sub_content', intro, linkObj, ['intro', 'media']);
      sub_content.push({
        contentId: "",
        sub_contentId: '',
        title: title,
        sub_title: sub_title,
        intro: content.intro,
        media: content.media,
    });
   
return sub_content;
}

public saveContent(obj: any){
  this.service.addContent(obj).subscribe(
    (res: any) => {
      console.log(res)
      this.toastrService.success(res.msg)
      alert('SUCCESS!! :-)' + res.msg);
    },
    err => {
      console.log(err);
    }
  );
}
}
