<!-- Navigation -->
<nav class="navbar navbar-expand-md navbar-dark bg-primary fixed-top">
  <div class="container-fluid">
    <a
      class="navbar-brand text-white"
      href="javascript:void(0)"
      routerLink="/dashboard"
      >NYSC NOTES</a
    >
    <button
      class="navbar-toggler"
      type="button"
      data-toggle="collapse"
      data-target="#navbarsExampleDefault"
      aria-controls="navbarsExampleDefault"
      aria-expanded="false"
      aria-label="Toggle navigation"
    >
      <span class="navbar-toggler-icon"></span>
    </button>
    <div class="collapse navbar-collapse" id="navbarsExampleDefault">
      <ul class="navbar-nav ml-auto">
        <li class="nav-item active">
          <a
            class="nav-link text-white"
            href="javascript:void(0)"
            routerLink="/state-guide"
            >State Guide</a
          >
        </li>
        <li class="nav-item">
          <a
            class="nav-link text-white"
            href="javascript:void(0)"
            routerLink="/content"
            >Content</a
          >
        </li>
      </ul>
    </div>
    <form class="form-inline">
      <div class="input-group">
        <input
          type="text"
          class="form-control"
          aria-label="Recipient's username"
          aria-describedby="button-addon2"
        />
        <div class="input-group-append">
          <button
            class="btn btn-outline-primary"
            type="button"
            id="button-addon2"
          >
            <i class="fa fa-search"></i>
          </button>
        </div>
      </div>
    </form>
  </div>
</nav>
<!-- Navigation -->

<!-- ============================================================== -->
<!-- Main wrapper - style you can find in pages.scss -->
<!-- ============================================================== -->
<div id="main-wrapper">
  <!-- ============================================================== -->
  <!-- Page wrapper  -->
  <!-- ============================================================== -->
  <div class="page-wrapper">
    <!-- ============================================================== -->
    <!-- Container fluid  -->
    <!-- ============================================================== -->
    <div class="container-fluid">
      <app-breadcrumb></app-breadcrumb>
      <!-- ============================================================== -->
      <!-- Start Page Content -->
      <!-- ============================================================== -->
      <router-outlet></router-outlet>
      <!-- ============================================================== -->
      <!-- End Start Page Content -->
      <!-- ============================================================== -->
    </div>
    <!-- ============================================================== -->
    <!-- End Container fluid  -->
    <!-- ============================================================== -->
  </div>
  <!-- ============================================================== -->
  <!-- End Page wrapper  -->
  <!-- ============================================================== -->
  <!-- ============================================================== -->
  <!-- footer -->
  <!-- ============================================================== -->

  <!-- ============================================================== -->
  <!-- End footer -->
  <!-- ============================================================== -->
</div>
