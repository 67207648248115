
<form
  name="form"
  (ngSubmit)="f.form.valid && onSubmit()"
  #f="ngForm"
  novalidate
>
  <div class=" gedf-card">

    <div class="card-body">
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          name="title"
          [(ngModel)]="note.title"
          #title="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && title.invalid }"
          required
          placeholder="Content Title"
        />
        <div *ngIf="f.submitted && title.invalid" class="invalid-feedback">
          <div *ngIf="title.errors.required">Title is required</div>
        </div>
      </div>
      <div class="form-group">
        <!-- <label for="title">Introduction</label> -->
        <quill-editor
          name="intro"
          format="object"
          [styles]="editorsStyle"
          [(ngModel)]="note.intro"
          #intro="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && intro.invalid }"
          placeholder="Introduction"
        ></quill-editor>
        <!-- <textarea name="intro" class="form-control" id="" cols="30" rows="10"  [(ngModel)]="note.intro" #intro="ngModel" [ngClass]="{ 'is-invalid': f.submitted && intro.invalid }" required placeholder="Introduction"> </textarea> -->
        <!-- <div *ngIf="f.submitted && intro.invalid" class="invalid-feedback">
            <div *ngIf="intro.errors.required">Introduction is required</div>
        </div> -->
      </div>
      <div class="form-group">
        <!-- <label for="sub_title">Subitle</label> -->
        <input
          type="text"
          class="form-control"
          name="sub_title"
          [(ngModel)]="note.sub_title"
          #sub_title="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && sub_title.invalid }"
          required
          placeholder="Content Subtitle"
        />
        <div *ngIf="f.submitted && sub_title.invalid" class="invalid-feedback">
          <div *ngIf="sub_title.errors.required">Subtitle is required</div>
        </div>
      </div>
    </div>
  </div>
  <div class=" gedf-card">
    <div class="card-header">
      <div class="d-flex justify-content-between align-items-center">
        <div class="d-flex justify-content-between align-items-center">
          <div class="mr-2">
            <!-- <img class="rounded-circle" width="45" src="https://picsum.photos/50/50" alt=""> -->
          </div>
          <div class="ml-2">
            <div class="h5 m-0">Sub Content</div>
          </div>
        </div>
      </div>
    </div>
    <div class="card-body">
      <!-- <div class="form-group">
                        <input type="text" class="form-control" name="sub_content_title" [(ngModel)]="note.sub_content_title" #sub_content_title="ngModel" [ngClass]="{ 'is-invalid': f.submitted && sub_content_title.invalid }" placeholder="Sub Content Title" />
                        <div *ngIf="f.submitted && sub_content_title.invalid" class="invalid-feedback">
                        </div>
                        </div> -->
      <div class="form-group">
        <!-- <label for="title">Title</label> -->
        <input
          type="text"
          class="form-control"
          format="object"
          name="sub_content_title"
          [(ngModel)]="note.sub_content_title"
          #sub_content_title="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && sub_content_title.invalid }"
          placeholder="Sub Content Title"
        />
        <div
          *ngIf="f.submitted && sub_content_title.invalid"
          class="invalid-feedback"
        ></div>
      </div>
      <div class="form-group">
        <!-- <label for="title">Introduction</label> -->
        <!-- <textarea
          name="sub_content_intro"
          class="form-control"
          id=""
          cols="30"
          rows="10"
          [(ngModel)]="note.sub_content_intro"
          #sub_content_intro="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && sub_content_intro.invalid }"
          placeholder="Sub Content Introduction"
        >
        </textarea> -->
                <quill-editor
                  name="sub_content_intro"
                  format="object"
                  id=""
                  [styles]="editorsStyle"
                  [(ngModel)]="note.sub_content_intro"
                  #sub_content_intro="ngModel"
          [ngClass]="{ 'is-invalid': f.submitted && sub_content_intro.invalid }"
          placeholder="Sub Content Introduction"
                ></quill-editor>
        <div
          *ngIf="f.submitted && sub_content_intro.invalid"
          class="invalid-feedback"
        ></div>
      </div>
      <div class="form-group">
        <!-- <label for="sub_title">Subitle</label> -->
        <input
          type="text"
          class="form-control"
          name="sub_content_sub_title"
          [(ngModel)]="note.sub_content_sub_title"
          #sub_content_sub_title="ngModel"
          [ngClass]="{
            'is-invalid': f.submitted && sub_content_sub_title.invalid
          }"
          placeholder="Sub Content Subtitle"
        />
        <div
          *ngIf="f.submitted && sub_content_sub_title.invalid"
          class="invalid-feedback"
        ></div>
      </div>

      <hr />

      <ng-template
        ngFor
        let-card
        [ngForOf]="note.card"
        let-index="index"
        let-isLast="last"
      >
        <!-- <div  [class.is-invalid]="( titleControl.touched && titleControl.invalid )"> -->
        <label style="float: right">
          <a
            (click)="removeCard(index)"
            href="javascript:void(0)"
            title="Remove Card"
            class="card-link"
          >
            <i class="fa fa-trash"></i>
          </a>
        </label>
        <div class="form-group">
          <input
            type="text"
            class="form-control"
            name="title_{{ card.id }}"
            [(ngModel)]="card.title"
            #titleControl="ngModel"
            placeholder="Card Title"
            [ngClass]="{ 'is-invalid': f.submitted && titleControl.invalid }"
          />
          <div
            *ngIf="f.submitted && titleControl.invalid"
            class="invalid-feedback"
          ></div>
        </div>

        <div class="form-group">
          <!-- <label for="title">Introduction</label> -->
          <quill-editor
          name="body_{{ card.id }}"
          format="object"
          [styles]="editorsStyle"
          [(ngModel)]="card.body"
          #bodyControl="ngModel"
  [ngClass]="{ 'is-invalid': f.submitted && bodyControl.invalid }"
  placeholder="Card Body"
        ></quill-editor>
          <div
            *ngIf="f.submitted && bodyControl.invalid"
            class="invalid-feedback"
          ></div>
        </div>
        <!-- </div> -->
      </ng-template>
    </div>
    <div class="card-footer">
      <a href="javascript:void(0)" (click)="addCard()" class="card-link"
        ><i class="fa fa-plus"></i> Add Card</a
      >
    </div>
  </div>

  <div class="form-group">
    <button class="btn btn-primary">Save</button>
  </div>
</form>
