<!-- <nav class="navbar navbar-light bg-white">
    <a href="#" class="navbar-brand">Bootsbook</a>
    <form class="form-inline">
        <div class="input-group">
            <input type="text" class="form-control" aria-label="Recipient's username" aria-describedby="button-addon2">
            <div class="input-group-append">
                <button class="btn btn-outline-primary" type="button" id="button-addon2">
                    <i class="fa fa-search"></i>
                </button>
            </div>
        </div>
    </form>
</nav> -->

<div class="content">
  <div class="container-fluid gedf-wrapper">
    <div class="row">
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="h5">{{ title }}</div>
            <div class="h7 text-muted">list of states</div>
            <div class="h7">
              <input
                type="text"
                class="form-control"
                placeholder="Search"
                [(ngModel)]="query"
                id="listSearch"
              />
            </div>
          </div>
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item"
              *ngFor="let item of state_list | searchFilter: query"
            >
              <a href="javascript:void(0)" (click)="getState(item)">
                {{ item.name }}</a
              >
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-9 gedf-main">
        <!--- \\\\\\\Post-->
     <state-edit
     [states]="states" [selected_states]="selected_states"
     (close_me)="closeChild($event)"
     *ngIf="selector"
     ></state-edit>
        <!-- Post /////-->
      </div>
      <!-- <div class="col-md-3">
        <div class="card gedf-card">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>
            <p class="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
            <a href="#" class="card-link">Card link</a>
            <a href="#" class="card-link">Another link</a>
          </div>
        </div>
        <div class="card gedf-card">
          <div class="card-body">
            <h5 class="card-title">Card title</h5>
            <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>
            <p class="card-text">
              Some quick example text to build on the card title and make up the
              bulk of the card's content.
            </p>
            <a href="#" class="card-link">Card link</a>
            <a href="#" class="card-link">Another link</a>
          </div>
        </div>
      </div> -->
    </div>
  </div>
</div>
