// import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
// import { FormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
// import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
// import { QuillModule } from 'ngx-quill';

import { ContentRoutes } from '@app/content/content.routing';
import { ContentComponent } from '@app/content/content/content.component';
import { NewContentComponent } from '@app/content/new/index.component';
import { EditContentComponent } from '@app/content/edit/index.component';

import { SharedModule } from '@app/shared';

@NgModule({
  imports: [
    // FormsModule,
    // CommonModule,
    // NgbModule,
    // QuillModule.forRoot(),
    RouterModule.forChild(ContentRoutes),
    SharedModule
  ],
  declarations: [ContentComponent, NewContentComponent, EditContentComponent]
})
export class ContentModule {}
