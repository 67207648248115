import { Routes } from '@angular/router';
import { AuthenticationGuard } from '@app/@core';

import { ContentComponent } from '@app/content/content/content.component';

export const ContentRoutes: Routes = [
  {
    path: '',
    canActivate: [AuthenticationGuard],
    children: [
      {
        path: '',
        component: ContentComponent,
        data: {
          title: 'Content'
        }
      }
    ]
  }
];
