
import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { FormatterService } from '../../@core/service';
// import { ContentComponent } from '../content';
import { ContentService } from '../../@core/service';
import { ToastrService } from 'ngx-toastr';

interface Card {
  id: string;
  cardId: string,
  card_MediumId: string,
  title: string,
  body: any,
  // {
  //   media: {}[],
  //   list: {}[],
  //   links: {}[]
  // }
}
@Component({
  selector: 'edit-content',
  templateUrl: './index.component.html',
  styleUrls: ['./index.component.css']
})
export class EditContentComponent implements OnInit  {
  @Input() selected_note: any;
  @Output() close_me: EventEmitter<boolean> = new EventEmitter<boolean>();
  public title: string = null;
  public card_image: any = [];
  public content_links: any = [];
  public card_links: any = {};

  // public  note: {
  //   title: '',
  //   content: '',
  // };
  public note: {
    card: Card[];
    title: string;
    intro: any;
    media: {}[];
    contentId?: string;
    sub_title: string;
    sub_content_title: string;
    sub_content_intro: any;
    sub_content_sub_title: string;
    sub_content: any;
  };
  //  public content: 'testing testing';

   editorsStyle = {
    height: '600px',
    'font-size': '16px'
   }
   cardEditorsStyle = {
    height: '300px',
    'font-size': '16px'
   }
  // public note: any = {};
  constructor(private formatterService: FormatterService, private service: ContentService,  private toastrService: ToastrService) {

  }
  ngOnChanges() {
    console.log(this.selected_note);
        // this.selected_note = JSON.parse(this.selected_note)
        // let p: any[] = null;
        let introduction: any = [];
        let content: any = []
        introduction = this.getContentForIntro(introduction);
        content = this.getContentForContent(content)
        // {attributes: {link: "one"}, insert: "one"} {attributes: {list: "bullet"}, insert: "↵"}
        // console.log('1',this.selected_note)
        this.note = null;
        this.note = {
          card: [],
          title: this.selected_note.title,
          intro: introduction,
          contentId: this.selected_note.contentId,
          sub_content: content,
          media: [],
          sub_title: this.selected_note.sub_title,
        sub_content_title: this.selected_note.sub_content[0].title || '',
        sub_content_intro:
          [
            {
              "insert": this.selected_note.sub_content[0].intro || ''
            }
          ],
        sub_content_sub_title: this.selected_note.sub_content[0].sub_title || '',
        };

        // Add an initial pet form-entry.
        if(this.selected_note.sub_content[0]){
          const selected_holder = this.selected_note.sub_content[0];
          if(selected_holder.cards.length >= 1){
           // let card_content: any = [];
            for (let i = 0; i < selected_holder.cards.length; i++) {
              console.log( selected_holder.cards);
             let card_content =  this.getContentForCard(selected_holder.cards[i]);
             console.log( card_content);
             if (card_content.media){
              this.card_image.push(card_content.media)
              delete card_content.media;
             }
             this.addCard(card_content)
            }
            //this.addCard(card_content);

          }
        }

  }
  created(editor: any) {
    // console.log(this.selected_note.media);
    if(this.selected_note.media && this.selected_note.media.length >= 1 && this.selected_note.media[0].url){
      const range = editor.getSelection(true);
      // console.log(this.selected_note.media[0].url)
      editor.insertEmbed(range.index, 'image', this.selected_note.media[0].url, 'user')   
    }
 }
 createdCard(editor: any, id: string) {
  console.log(id,this.card_image );
   if(this.card_image && this.card_image.length >= 1){
   const found = (this.card_image.find((el: any) => el.cardId === id)) 
   if(found) {
    const range = editor.getSelection(true);
    console.log(range);
    editor.insertEmbed(range.index, 'image', found.url, 'user')
   }

  
   }
}
 createdSubContent(editor: any) {
  console.log(this.selected_note.sub_content[0].media.length);
  if(this.selected_note.sub_content  &&  this.selected_note.sub_content.length >= 1 && this.selected_note.sub_content[0].media &&  this.selected_note.sub_content[0].media.length >= 1) {
    console.log(this.selected_note.sub_content[0].media[0]);
    if(this.selected_note.sub_content[0].media[0].url){
    console.log('then here');
    const range = editor.getSelection(true);
    editor.insertEmbed(range.index, 'image', this.selected_note.sub_content[0].media[0].url, 'user')
  }
  }
}

  ngOnInit() {

  }
  public addCard(obj: any = null): void {
    if(obj){
      this.note.card.push(obj);
      //console.log(obj);
    }else {
      this.note.card.push({
        id: Date.now() + this.formatterService.makeid(9), // <--- uniqueness hook.
        cardId: '',
        card_MediumId: '',
        title: '',
        body: null,
      })
    }
  }

  public removeCard(index: number): void {
    this.note.card.splice(index, 1);
  }
  saveNote(event: any) {
    console.log(this.title);
    // this.todos.push(this.todoObj);
    event.preventDefault();
  }
  onSubmit() {
   this.formatText(this.note);
    event.preventDefault();
  }
  public formatText(note: any) {
 
      const intro = note.intro;
     // console.log(note.intro);
    const title = note.title
    const sub_title = note.sub_title
    const title_slug = this.formatterService.slugly(title);
    const published_at = this.formatterService.setPublishDate();
    const linkObj: any = {};
    linkObj.linkArray = this.content_links;
    linkObj.contentId = this.selected_note.contentId;
    linkObj.mediumId = (this.selected_note.media.length >= 1) ? this.selected_note.media[0].mediumId : '';
    // console.log('intro', intro, linkObj);
    const content = this.formatterService.getContent2('content', intro, linkObj, ['intro', 'media', 'links']);
    // console.log('intro', content);
    const sub_content  = this.subContent(note, this.selected_note.contentId, this.selected_note.sub_content[0].sub_contentId);
    content.contentId =  this.selected_note.contentId;
    const cards  = this.getCards(note, this.selected_note.contentId);
    sub_content[0].cards = cards;
    content.sub_content = sub_content;
    content.title = title;
    content.sub_title = sub_title;
    content.title_slug = title_slug;
    content.published_at = published_at;
    content.categories = this.selected_note.categories;
    content.tags = [];
    console.log(JSON.stringify(content))
    // if(content.links && content.links.length >= 1 ) {
    //   console.log(content.links);
    // } else {
    //   delete content.links
    // }
    content.logo = "https://gallery.mailchimp.com/7532fb9ddd325fea5d2e3d3ba/images/1e968ef0-6935-4a3f-a725-ac7d47d8d300.png",
    // const links = this.formatterService.getLinks(note.intro);
    // alert('SUCCESS!! :-)' + JSON.stringify(content.media));
    this.saveContent(content);
    // console.log(JSON.stringify(content));
    event.preventDefault();
  }

  public getCards(note: any, id: string) {
    let cards: any[] = [];
    const card = note.card;
     if(card.length > 0){
      for (let i = 0; i < card.length; i++) {
        const title = card[i].title;
        const linkObj: any = {};
        linkObj.cardId = card[i].cardId;
        linkObj.linkArray = this.card_links[linkObj.cardId];
        console.log('array2', linkObj.cardId, JSON.stringify(linkObj.linkArray));
        linkObj.card_MediumId = (card[i].card_MediumId ) ? card[i].card_MediumId : '';  
        const content = this.formatterService.getContent2('card', card[i].body, linkObj, ['intro', 'media', 'links']);
       let card_obj: any = {
        cardId: card[i].cardId,
        // contentId: id,
        title: title,
        body: content.intro,
        media: content.media,
        list: content.list
    }
    // console.log(content.links);    

    if (content.links.length >= 1 && content.links[0].text ) { card_obj.links = content.links };
      cards.push(card_obj);
     }
    
  }
  console.log(cards);

 return cards;
}
public subContent(note: any,  id: string, sub_contentId: string) {
  
  let sub_content: any[] = [];
  const intro = note.sub_content_intro;
      const title = note.sub_content_title;
      const sub_title = note.sub_content_sub_title;
      const linkObj: any = {};
      linkObj.contentId = this.selected_note.contentId;
      linkObj.sub_contentId = this.selected_note.sub_content[0].sub_contentId;
      linkObj.sub_MediumId = ( this.selected_note.sub_content[0].media.length >= 1) ? this.selected_note.sub_content[0].media[0].sub_MediumId: '';
      const content = this.formatterService.getContent2('sub_content', intro, linkObj, ['intro', 'media']);
      sub_content.push({
        contentId: id,
        sub_contentId,
        title: title,
        sub_title: sub_title,
        intro: content.intro,
        media: content.media,
    });
    console.log(JSON.stringify(sub_content[0].intro));

return sub_content;
}

public saveContent(obj: any){
  this.service.addContent(obj).subscribe(
    (res: any) => {
      console.log(res)
      this.toastrService.success(res.msg);
    alert('SUCCESS!! :-)' + res.msg);
    event.preventDefault();
    },
    err => {
      console.log(err);
    }
  );
}

public getContentForIntro(introduction: {}[]){
  if(this.selected_note.intro){
    introduction.push(
    {
    "insert": this.selected_note.intro
    });
}

if(this.selected_note.links) {
  // introduction.push({"insert": "\n"});
  for (let i = 0; i < this.selected_note.links.length; i++) {
   if(this.selected_note.links[i].text) {
    this.content_links.push({ id:i, linkId: this.selected_note.links[i].linkId });
    introduction.push({attributes: {link: this.selected_note.links[i].url || ''}, insert: this.selected_note.links[i].text}, {attributes: {list: "bullet"}, insert: "\n"})
 
   } else {
    this.content_links.push({ id:i, linkId: this.selected_note.links[i].linkId });
    introduction.push({attributes: {link: this.selected_note.links[i].url || ''}, insert: '---'}, {attributes: {list: "bullet"}, insert: "\n"})
 
   }
 
}
}
// console.log(this.content_links);
return introduction;
}

public getContentForContent(content: {}[]){
  if(this.selected_note.sub_content[0] ){
    if(this.selected_note.sub_content[0].intro ){
    content.push(
    //   {
    //   "insert": "\n"
    // },
    {
    "insert": this.selected_note.sub_content[0].intro
    },
    // {"insert": "\n"}
    );
  }
}
return content;
}


public getContentForCard(card_content: any){
        let body: any = [];
        const title = card_content.title;
        let media = null;
        const id = Date.now() + this.formatterService.makeid(9);
        if(card_content.body != "") {
        body.push(
        //   {
        //   "insert": "\n"
        // },
        {
        "insert": card_content.body
        });
      }
if(card_content.links) {
  // body.push({"insert": "\n"});
  var obj: any= {};
  var objArray: any = [];
  for (let i = 0; i < card_content.links.length; i++) {
    // console.log(card_content.links[i]);
    if(card_content.links[i].text) {
    //  this.card_links.card_content.cardId.push({ id:i, linkId: card_content.links[i].linkId });
     body.push({attributes: {link: card_content.links[i].url || ''}, insert: card_content.links[i].text}, {attributes: {list: "bullet"}, insert: "\n"})
    } else {
      body.push({attributes: {link: card_content.links[i].url || ''}, insert: '---'}, {attributes: {list: "bullet"}, insert: "\n"})
    }
    objArray.push({ id:i, card_LinkId: card_content.links[i].card_LinkId });
 }
 this.card_links[card_content.cardId] = objArray;
//  this.card_links.push(obj);
console.log('array1', JSON.stringify(this.card_links))
}  
const card_MediumId = (card_content.media[0]) ? card_content.media[0].card_MediumId : '';
const cardId =  card_content.cardId;

if(card_content.media.length >= 1 && card_content.media[0].url) {
  // for (let i = 0; i < card_content.media.length; i++) {
   media = { url: card_content.media[0].url, card_MediumId, cardId };
  // }
}  
return  {
    id, // <--- uniqueness hook.
    cardId,
    card_MediumId,
    title,
    body,
    media,
  };
}
}
