<!-- <nav class="navbar navbar-light bg-white">
    <a href="#" class="navbar-brand">Bootsbook</a>
    <form class="form-inline">
        <div class="input-group">
            <input type="text" class="form-control" aria-label="Recipient's username" aria-describedby="button-addon2">
            <div class="input-group-append">
                <button class="btn btn-outline-primary" type="button" id="button-addon2">
                    <i class="fa fa-search"></i>
                </button>
            </div>
        </div>
    </form>
</nav> -->

<div class="content">
  <div class="container-fluid gedf-wrapper">
    <div class="row">
      <div class="col-md-3">
        <div class="card">
          <div class="card-body">
            <div class="h5">{{ title }}</div>
            <!-- <div class="h7 text-muted">...</div> -->
            <div class="h7">
              <input
                type="text"
                class="form-control"
                placeholder="Search"
                [(ngModel)]="query"
                id="listSearch"
              />
            </div>
          </div>
          <ul class="list-group list-group-flush">
            <li
              class="list-group-item"
              *ngFor="let item of category_list | searchFilter: query"
            >
              <span class="col-xs-12 col-sm-3">
                <a href="javascript:void(0)" (click)="open(content, item)">{{
                  item.name
                }}</a>
              </span>
              <span class="col-xs-12 col-sm-9">
                <a
                  href="javascript:void(0)"
                  (click)="getCategory(item)"
                  class="card-link"
                  ><i class="fa fa-sticky-note"></i>
                </a>
              </span>
              <!-- <a href="javascript:void(0)" (click)="getCategory(item.code)"> {{item.name}}</a> -->
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-9 gedf-main">
        <!--- \\\\\\\Post-->
        <edit-content
          [selected_note]="selected_note"
          (close_me)="closeChild($event)"
          *ngIf="selector == 'edit'"
        ></edit-content>
        <new-content
          [selected_category]="selected_category"
          (close_me)="closeChild($event)"
          *ngIf="selector == 'new'"
        ></new-content>

        <!-- Post /////-->
      </div>
      <!-- <div class="col-md-2">
                <div class="card gedf-card">
                    <div class="card-body">
                        <h5 class="card-title">Card title</h5>
                        <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>
                        <p class="card-text">Some quick example text to build on the card title and make up the bulk of the
                            card's content.</p>
                        <a href="#" class="card-link">Card link</a>
                        <a href="#" class="card-link">Another link</a>
                    </div>
                </div>
                <div class="card gedf-card">
                        <div class="card-body">
                            <h5 class="card-title">Card title</h5>
                            <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>
                            <p class="card-text">Some quick example text to build on the card title and make up the bulk of the
                                card's content.</p>
                            <a href="#" class="card-link">Card link</a>
                            <a href="#" class="card-link">Another link</a>
                        </div>
                    </div>
            </div> -->
    </div>
  </div>
  <ng-template #content let-modal>
    <div class="modal-header">
      <!-- <h4 class="modal-title" id="modal-basic-title">Profile update</h4> -->
      <div class="odal-title">
        <input
          type="text"
          class="form-control"
          placeholder="Search"
          [(ngModel)]="query2"
          id="noteSearch"
        />
      </div>
      <button
        type="button"
        class="close"
        aria-label="Close"
        (click)="modal.dismiss('Cross click')"
      >
        <span aria-hidden="true">&times;</span>
      </button>
    </div>
    <div class="modal-body" *ngIf="note">
      <div
        class="card gedf-card"
        *ngFor="let item of note | searchFilter: query2"
      >
        <div class="card-body">
          <h5 class="card-title">{{ item?.title }}</h5>
          <h6 class="card-subtitle mb-2 text-muted">Card subtitle</h6>
          <p class="card-text">{{ item?.intro }}</p>
          <a href="javascript:void(0)" class="card-link"
            ><i class="fa fa-trash"></i> delete
          </a>
          <a
            href="javascript:void(0)"
            class="card-link"
            (click)="editNote(item)"
            ><i class="fa fa-pencil"></i> edit
          </a>
        </div>
      </div>
    </div>
    <!-- <div class="modal-footer">
              <button type="button" class="btn btn-outline-dark" (click)="modal.close('Save click')">Save</button>
            </div> -->
  </ng-template>
</div>
