
<form
name="form"
(ngSubmit)="f.form.valid && onSubmit()"
#f="ngForm"
novalidate
>
<div class=" gedf-card">

  <div class="card-body">
    <div class="form-group">
      <input
        type="text"
        class="form-control"
        name="title"
        [(ngModel)]="note.title"
        #title="ngModel"
        [ngClass]="{ 'is-invalid': f.submitted && title.invalid }"
        required
        placeholder="Content Title"
      />
      <div *ngIf="f.submitted && title.invalid" class="invalid-feedback">
        <div *ngIf="title.errors.required">Title is required</div>
      </div>
    </div>
    <div class="form-group">
            <input
              type="hidden"
              class="form-control"
              name="stateguideId"
              [(ngModel)]="note.stateguideId"
              #stateguideId="ngModel"
              [ngClass]="{ 'is-invalid': f.submitted && stateguideId.invalid }"
            />
          </div>
 
    <div class="form-group">
      <!-- <label for="title">Introduction</label> -->
      <quill-editor
        name="content"
        format="object"
        [styles]="editorsStyle"
        [(ngModel)]="note.content"
        #content="ngModel"
        (onEditorCreated)="created($event)"
        [ngClass]="{ 'is-invalid': f.submitted && content.invalid }"
        placeholder="Introduction"
      ></quill-editor>
    </div>
  </div>
</div>

<div class=" gedf-card">
  <div class="card-header">
    <div class="d-flex justify-content-between align-items-center">
      <div class="d-flex justify-content-between align-items-center">
        <div class="mr-2">
          <!-- <img class="rounded-circle" width="45" src="https://picsum.photos/50/50" alt=""> -->
        </div>
        <div class="ml-2">
          <div class="h5 m-0">Sub Content</div>
        </div>
      </div>
    </div>
  </div>
  <div class="card-body">

 
    <hr />
    <div class="row">
    <ng-template
      ngFor
      let-card
      [ngForOf]="note.card"
      let-index="index"
      let-isLast="last"
    >
    <!-- {{note.color}} -->
    <div class="col-md-6">
      <!-- <div  [class.is-invalid]="( titleControl.touched && titleControl.invalid )"> -->
      <label style="float: right">
        <a
          href="javascript:void(0)"
          title="Remove Card"
          class="card-link"
        >
          <i class=""> {{index + 1}}</i>
        </a>
      </label>
      <div class="form-group">
        <input
          type="text"
          class="form-control"
          name="title_{{ card.id }}"
          [(ngModel)]="card.title"
          #titleControl="ngModel"
          placeholder="Card Title"
          required
          [ngClass]="{ 'is-invalid': f.submitted && titleControl.invalid }"
        />
        <div
          *ngIf="f.submitted && titleControl.invalid"
          class="invalid-feedback"
        ></div>
      </div>
      <div class="form-group">
            <input
              type="hidden"
              class="form-control"
              name="card_id_{{card.id}}"
              [(ngModel)]="card.card_id"
              #cardIdControl="ngModel"
              placeholder="Card id"
              [ngClass]="{ 'is-invalid': f.submitted && cardIdControl.invalid }"
            />
          </div>
    
      <div class="form-group">
        <!-- <label for="title">Introduction</label> -->
        <quill-editor
        name="body_{{ card.id }}"
        format="object"
        [styles]="cardEditorsStyle"
        [(ngModel)]="card.body"
        #bodyControl="ngModel"
        (onEditorCreated)="createdCard($event, card.id)"
[ngClass]="{ 'is-invalid': f.submitted && bodyControl.invalid }"
placeholder="Card Body"
      ></quill-editor>
        <div
          *ngIf="f.submitted && bodyControl.invalid"
          class="invalid-feedback"
        ></div>
      </div>
      </div>
    </ng-template>
    </div>
  </div>
  <div class="card-footer">
    <!-- <a href="javascript:void(0)" (click)="addCard()" class="card-link"
      ><i class="fa fa-plus"></i> Add Card</a
    > -->
  </div>
</div>

<div class="form-group">
  <button class="btn btn-primary">Save</button>
</div>
</form>
