import { Component } from '@angular/core';
import { StateList } from '../../@core/model/state';
import { StateService } from '../../@core/service';
@Component({
  templateUrl: './state-guide.component.html',
  styleUrls: ['./state-guide.component.css']
})
export class StateGuideComponent {
  state_list: any[];
  title = 'State Guide';
  public selected_states: any = {}
  public search: any = '';
  public data: any = {};
  public query : any = '';
  public selector: boolean = false;
  public states: any;
  //  {
  //   intro: '',
  //   content: '',
  //   title: ''
  // };
  constructor(private service: StateService) {
    this.selector = false;
    this.state_list = StateList;
  }

  getState(item: any) {
    console.log(item);
    this.selector = false;
    this.service.getState(item.code).subscribe(
      (res: any) => {
        this.selector = true;
        this.selected_states = item;
    this.states =  res.data[0];

        // Object.keys(res).forEach((key) => {
        //   this.data[key] = res[key];
        // });
      },
      err => {
        console.log(err);
      }
    );
  }
}
