import {
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest,
  HttpResponse
} from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '@env/environment';
import { Observable,  of, throwError } from 'rxjs';
import  user from '../authentication/users';



/**
 * Prefixes all requests with `environment.serverUrl`.
 */
@Injectable()
export class ApiPrefixInterceptor implements HttpInterceptor {
  constructor() {}

  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    let users: any[] = JSON.parse(localStorage.getItem('users')) || user;
    request = request.clone({
      url: environment.serverUrl + request.url
    });
    if (request.url.endsWith('/login') && request.method === 'POST') {
      // find if any user matches login credentials
      let filteredUsers = users.filter(user => {
          return user.username === request.body.username && user.password === request.body.password;
      });

      if (filteredUsers.length) {
          // if login details are valid return 200 OK with user details and fake jwt token
          let user = filteredUsers[0];
          let body = {
              id: user.id,
              username: user.username,
              firstName: user.firstName,
              lastName: user.lastName,
              token: 'fake-jwt-token'
          };

          return of(new HttpResponse({ status: 200, body: body }));
      } else {
          // else return 400 bad request
          return throwError({ error: { message: 'Username or password is incorrect' } });
      }
  }

    return next.handle(request);
  }
}
