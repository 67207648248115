
export default [
    {
id: "jfdkvjsfksfknsnofsdnj",
firstname: 'kolawole',
lastname: 'olalelakan',
username: "kolawoleme@kominity.com",
password: "123456"
},
{
    id: "jfdkvjsdjdjdjdjsdjsfksfknsnofsdnj",
    firstname: 'segun',
    lastname: 'ogunwale',
    username: "segun@kominity.com",
    password: "123456"
},
{
    id: "jfcdjdjddkvdkdkjsfksfknsnofsdnj",
    firstname: 'kolawole',
    lastname: 'olalelakan',
    username: "kolawoleme@gmail.com",
    password: "123456"
},
{   
    id: "ghjdfjwewsopssfksfknsnofsdnj",
    firstname: 'tomiwa',
    lastname: 'okunaiki',
    username: "tomiwa@kominity.com",
    password: "123456"
    },
    {   
        id: "fdkvjsdjdieewwfksfknsnofsdnj",
        firstname: 'wale',
        lastname: 'akanbi',
        username: "wale@kominity.com",
        password: "123456"
        }
]